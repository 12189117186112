
import { defineComponent, Ref, ref } from '@vue/runtime-core'
import { DefaultInput, ModalForm, FormErrors, RelationSelect } from 'apptimizm-ui'
import axios from '@/axios'
import { DefaultTableExposed } from 'apptimizm-ui/src/ui/default-table/default-table'
import { Project, ProjectMeta } from './types'
import Table from './table.vue'
import useForm from '@/composables/use-form'
import { store, mutations } from '../../store'
import { getCeleryTask } from '../../core/celery'
import { ButtonLoader } from '@/UI/loader'
import { host } from '@/const'
import EditModalForm from './edit-form'
import { computed, onMounted } from 'vue'

export default defineComponent({
  setup () {
    const table = ref(null) as unknown as Ref<DefaultTableExposed>
    const projectMeta: ProjectMeta = new ProjectMeta()
    const isLoading = ref<{[ code: string ]: boolean}>({})
    const isProjectSelected = computed(() => !!store.currentProject.id)

    const editItemProject: Ref<Project|null> = ref(null)
    const errorsEditProject = ref(new FormErrors({}))
    const showPopup: Ref<boolean> = ref(false)
    const toggle = () => { showPopup.value = !showPopup.value }

    const { item: modalFormFields, errors, save } = useForm(axios, projectMeta, 'projects')

    const submit = async () => {
      errors.value = new FormErrors({})
      await save()

      if (!Object.keys(errors.value.data).length) {
        toggle()
        table.value.reload()
      }
    }

    const downloadProject = async (e: Event, item: Project) => {
      e.stopPropagation()

      isLoading.value[item.id] = true
      try {
        const { result_id: resultId } = (await axios.post(projectMeta.endpoint + item.id + '/export_project')).data
        const { data } = await getCeleryTask(resultId)
        if (data.result) {
          window.open(data.result)
          mutations.pushNotification(`Проект ${item.name} выгружен`)
        } else {
          mutations.pushNotification(`Ошибка выгрузки проекта ${item.name}`, true)
        }
      } catch {
        mutations.pushNotification(`Ошибка выгрузки проекта ${item.name}`, true)
      }

      delete isLoading.value[item.id]
    }

    const deleteProject = async (e: Event, id: string) => {
      e.stopPropagation()

      let name = ''
      try {
        name = (await axios.get(projectMeta.endpoint + id)).data.title
      } catch {
        mutations.pushNotification('Произошла ошибка при удалении', true)
      }
      try {
        await axios.delete(projectMeta.endpoint + id)
        if (id === store.currentProject.id) mutations.setCurrentProject({ id: '', name: '', address: '' })
        table.value.reload()
        mutations.pushNotification(`Проект ${name} удален`)
      } catch {
        mutations.pushNotification('Произошла ошибка при удалении', true)
      }
    }

    const toggleEditProject = (e: Event, item?: Project) => {
      e.stopPropagation()

      if (item) editItemProject.value = item
      else editItemProject.value = null
      errorsEditProject.value = new FormErrors({})
    }

    const editProject = async () => {
      if (!editItemProject.value) return

      const { data } = await axios.patch(projectMeta.endpoint + editItemProject.value.id, projectMeta.dump(editItemProject.value))

      if (data.errors) errorsEditProject.value = new FormErrors(data.errors)
      else editItemProject.value = null
    }

    const loadProject = async (project: FileList) => {
      isLoading.value.loadProject = true
      try {
        const formData = new FormData()
        formData.append('file', project[0])
        const result = (await axios.post(host + '/api/v1/file', formData)).data

        const response = (await axios.post(host + '/api/v1/project/import_project/', {
          file: result.id
        })).data

        const celaryResult = await getCeleryTask(response.result_id)
        if (celaryResult) {
          window.open(celaryResult.data.result)
          mutations.pushNotification('Проект успешно загружен')
          table.value.reload()
        } else mutations.pushNotification('Ошибка загрузки проекта', true)
        isLoading.value.loadProject = false
      } catch (e) {
        mutations.pushNotification('Ошибка загрузки проекта', true)
        isLoading.value.loadProject = false
      }
    }

    const tableFilter = {
      search: ref(''),
      get tableParams () {
        const res: { [code: string]: string } = {}
        if (this.search.value) res.search = this.search.value

        return res
      }
    }

    const modalForm = () => {
      return (
        <ModalForm cancel={toggle} confirm={() => {}} header="Новый проект"
          slots={{
            default: () => {
              return (
                <div>
                  <label class="mt-4">
                    <DefaultInput
                      class={modalFormFields.value.name ? 'input-placeholder-up' : ''}
                      placeholder="Название проекта" modelValue={modalFormFields.value.name}
                      onValueChange={(v: string) => { modalFormFields.value.name = v }}
                      errors={errors.value.forField('name')}
                    />
                  </label>
                  <label class="mt-4">
                    <DefaultInput
                      class={modalFormFields.value.address ? 'input-placeholder-up' : ''}
                      placeholder="Адрес" modelValue={ modalFormFields.value.address}
                      onValueChange={(v: string) => { modalFormFields.value.address = v }}
                      errors={errors.value.forField('address')}
                    />
                  </label>
                </div>
              )
            },
            buttons: () => <button onClick={submit} class="primary-button mt-4">Создать</button>
          }}
        />
      )
    }

    onMounted(async () => {
      if (!isProjectSelected.value && localStorage.projectID) {
        try {
          const result = projectMeta.load((await axios.get(`${projectMeta.endpoint}${localStorage.projectID}`)).data)
          if (result?.id) mutations.setCurrentProject({ id: result.id, name: result.name, address: result.address })
        } catch (error) {
          mutations.pushNotification('Не удалось получить выбранный проект', true)
        }
      }
    })

    return () => (
      <div>
        <div class="page-top-panel">
          <h1>Проекты</h1>
          <div class="page-top-panel-row mt-5">
            <label>
              <RelationSelect
                axios={axios}
                placeholder="Проект"
                constantPlaceholder={false}
                modelValue={store.currentProject}
                endpoint={projectMeta.endpoint + '?params=id'}
                itemConverter={(v: any) => projectMeta.load(v)}
                onValueChange={(v: any) => {
                  localStorage.projectID = v?.id || ''
                  mutations.setCurrentProject(v)
                }}
                responseItemsKey="results"
                responseTotalKey="count"
              />
            </label>
            <label>
              <DefaultInput
                class={tableFilter.search.value ? 'search input-placeholder-up' : 'search'}
                placeholder="Проект"
                onValueChange={(v :string) => { tableFilter.search.value = v }}
                modelValue={tableFilter.search.value}
              />
            </label>
            <button class="button-add right-item" onClick={toggle}>Добавить проект</button>
            <button class={`button-loading ${isLoading.value.loadProject ? 'hide-icon' : ''}`}>
              <div style="position: absolute; top: 50%; left: 0; transform: translateY(-50%); display: flex; padding-left: 14px;">
                <ButtonLoader hidden={!isLoading.value.loadProject}/>
              </div>
              <input class="hidden" type="file" onChange={(e: Event) => loadProject((e.target as HTMLFormElement).files)}/>
              Загрузить проект
            </button>
          </div>
        </div>
        <div class="layout">
          <Table
            table={table}
            tableFilter={tableFilter}
            editProject={toggleEditProject}
            deleteProject={deleteProject}
            downloadProject={downloadProject}
            isLoading={isLoading}
          />
        </div>
        { showPopup.value ? modalForm() : null }
        { editItemProject.value && EditModalForm(editItemProject.value, toggleEditProject, editProject, errorsEditProject.value) }
      </div>
    )
  }
})
